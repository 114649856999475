<template>
  <div></div>
</template>

<script>
export default {
  name: 'silentSsoCheck',
  mounted () {
    parent.postMessage(location.href, location.origin)
  }
}
</script>
